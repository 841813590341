body {
    margin: 0;
}

/**** reports *****/
.pvtUi {
    width: 100% !important;
}

/**** tables fix ****/
table.jexcel {
    /*width: 100%;*/
}
.jexcel > tbody > tr > td {
    white-space: normal !important;
    word-break: break-all;
    height: 26px;
}
.jexcel tr td:first-child {
    width: 26px;
    padding: 0;
}

html {
    background: #fafafa;
    min-height: 100%;
}

html.highContrast {
    filter: invert(90%);
    background: #191919;
}

html.highContrast * {
    color: #0000ff;
}
html.highContrast .MuiTooltip-tooltip,
html.highContrast .MuiButton-root .MuiButton-label,
html.highContrast .MuiFab-primary .MuiButton-label,
html.highContrast .MuiFab-primary * {
    color: #fff;
}
html.highContrast .MuiAppBar-colorPrimary {
    background-color: #fff;
}

html.highContrast .MuiFab-primary,
html.highContrast .MuiButton-root,
html.highContrast .MuiTooltip-tooltip {
    background-color: #00f;
}

html.compact nav .MuiListItemIcon-root {
    min-width: auto;
}
header, nav, nav * {
    -webkit-transition : width 500ms ease !important;
    -moz-transition    : width 500ms ease !important;
    -o-transition      : width 500ms ease !important;
    transition         : width 500ms ease !important;
}

@media print {
    @page {
        margin-top: 0.2in;
        margin-bottom: 0.2in;
        margin-left: 0.0in;
        margin-right: 0.0in;
    }

    nav {
        display: none;
    }

    #root > div, .MuiExpansionPanelSummary-root, .MuiCollapse-wrapper {
        display: block !important;
    }

    * {
        transition: none !important;
        font-size: 10px !important;
    }

    body {
        background-color: red !important;
        margin: 0 !important;
    }

    *[class^="NCPollAnswer-buttonsWrapper"] {
        display: none !important;
    }

    *[class^="NCPollAnswer-panelTitle"] {
        padding-bottom: 0 !important;
    }

    .MuiIconButton-root {
        display: none !important;
    }

    .MuiFormGroup-root .MuiIconButton-root {
        display: inline !important;
    }

    .MuiFormControl-marginNormal {
        margin-top: 4px;
        margin-bottom: 2px;
    }

    .MuiCollapse-hidden {
        visibility: visible !important;
        height: auto !important;
    }

    .MuiExpansionPanel-rounded:last-child {
        border-radius: 0 !important;
    }

    .MuiPaper-elevation1 {
        box-shadow: none !important;
    }

    .MuiFormControl-marginNormal {
        margin-top: 0 !important;
    }

    .MuiListItem-gutters {
        display: none !important;
    }

    .MuiExpansionPanelDetails-root {
        padding-bottom: 4px !important;
        padding-top: 4px !important;
    }

    /*.MuiPaper-root {
        padding: 4px !important;
    }*/
    .MuiAppBar-root {
        display: none !important;
    }

    .pollActionButtons {
        display: none !important;
    }

    .poll-empty input {
        color: transparent !important;
    }

    .poll-empty .Mui-checked svg:nth-child(2) {
        display: none !important;
    }

    .poll-empty span[class*="NCPollAnswer-editIconWrapper"] {
        display: none !important;
    }

    table.jexcel td:not(.cell-label) {
        font-size: 0 !important;
    }

    table.jexcel td[class=""] {
        font-size: 10px !important;
    }
}
